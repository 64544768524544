import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { HttpHeaders } from '@angular/common/http';

export const menuCtrl = {
  disableTransportBtn: false,
  disableDistBtn: false,
};

export const ngxLoadingConfig = {
  animationType: ngxLoadingAnimationTypes.circleSwish,
  backdropBackgroundColour: 'rgba(0,0,0,0.5)',
  backdropBorderRadius: '30px',
  primaryColour: '#B10064',
  secondaryColour: '#F18E00',
  tertiaryColour: '#FFDD00',
};
