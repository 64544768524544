<div class="login-container">
  <div class="content-container">
    <div class="logo-container">
      <img src="assets/images/logoAkilee.svg" alt="" />
    </div>

    <h1 style="font-size: 500%; color: white">En Maintenance</h1>
    <p style="font-size: 150%; color: white">
      Notre système est en maintenance. Veuillez reéssayer plus tard
    </p>
  </div>
</div>
