// Section 1
import { Action } from '@ngrx/store';
import { IDate } from '../models/date.model';

// Section 2
export const SET_TEMPORAL_NAVIGATION_RANGE = '[TEMPORAL_NAVIGATION] SET_RANGE';

export class SetTemporalRange implements Action {
  readonly type = SET_TEMPORAL_NAVIGATION_RANGE;

  constructor(public payload: IDate) {}
}

// Section 4
export type Actions = SetTemporalRange;
