import { Component, OnInit } from '@angular/core';
import { AuthenticateService } from '../../services/authenticate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ngxLoadingAnimationTypes } from 'ngx-loading';

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss'],
})
export class ResetPasswordPageComponent implements OnInit {
  password: string;

  passwordConfirm: string;

  returnUrl: string;

  error = '';

  ngxLoadingConfig;

  loading = false;

  code: string;

  constructor(
    private authService: AuthenticateService,
    private route: ActivatedRoute,
    private notificationService: NotificationsService,
    private router: Router,
  ) {}

  ngOnInit() {
    // get return url from route parameters or default to '/admin/real-time'
    this.code = this.route.snapshot.queryParams.code;
    this.ngxLoadingConfig = {
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.5)',
      backdropBorderRadius: '7px',
      primaryColour: '#B10064',
      secondaryColour: '#F18E00',
      tertiaryColour: '#FFDD00',
    };
  }

  onSubmit() {
    if (this.password && this.passwordConfirm) {
      this.loading = true;
      this.authService.ModifyPassword(this.password, this.passwordConfirm, this.code).subscribe(
        () => {
          this.loading = false;
          this.notificationService.success(
            'Mot de passe mis à jour avec succes',
            'Vous pouvez désormais vous connecter avec votre nouveau mot de passe.',
            {
              timeOut: 5000,
              showProgressBar: false,
              pauseOnHover: true,
              clickToClose: false,
              animate: 'fromRight',
            },
          );
          this.router.navigate(['/login']);
        },
        () => {
          this.notificationService.error(
            'Désolé!!',
            'Le délai de réinitialisation du mot de passe est dépassé',
            {
              showProgressBar: false,
              pauseOnHover: true,
              clickToClose: true,
              animate: 'fromRight',
            },
          );
          this.loading = false;
        },
      );
      this.password = '';
      this.passwordConfirm = '';
    } else {
      this.notificationService.error(
        'Erreur',
        'Veuillez vérifier que les deux mots de passe saisis sont identiques.',
        {
          timeOut: 5000,
          showProgressBar: false,
          pauseOnHover: true,
          clickToClose: false,
          animate: 'fromRight',
        },
      );
    }
  }
}
