export const environment = {
  production: false,
  name: 'develop',
  server: window['env']['CORE_SERVER'],
  serverNotif: window['env']['NOTIF_SERVER'],
  WEBSOCKET_HOST: window['env']['WEBSOCKET_HOST'],
  serverTransfo: window['env']['TRANSFO_SERVER'],
  serverPhotos: window['env']['PHOTO_SERVER'],
  reportServer: window['env']['REPORT_SERVER'],
  globalDomain: window['env']['GLOBAL_DOMAIN'],
  version: window['env']['VERSION'],
  uipServer: window['env']['UIP_SERVER'],
};
