import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Intercepteur qui rajoute le token d'authentification de l'utilisateur
 * dans toutes les requêtes HTTP sortantes.
 */
@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    const currentUser = JSON.parse(localStorage.getItem('userToken'));
    if (currentUser && currentUser.accessToken) {
      if (!request.headers.get('Authorization')) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        });
      }
    }

    return next.handle(request);
  }
}
