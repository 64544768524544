import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticateService {
  constructor(private http: HttpClient, private permissionsService: NgxPermissionsService) {}

  changePassword(currentPassword: string, password: string, confirmPassword: string) {
    const httpOptions = {
      headers: new HttpHeaders({}),
    };
    return this.http
      .put<any>(
        environment.server + '/users/change-password',
        {
          current_password: currentPassword,
          password: password,
          password_confirmation: confirmPassword,
          first_connexion: false,
        },
        httpOptions,
      )
      .pipe(
        map((response) => {
          const currentToken = JSON.parse(localStorage.getItem('userToken'));

          currentToken.user.first_connexion = false;

          return response;
        }),
      );
  }

  forgetPassword(mail: string, username: string) {
    const httpOptions = {
      headers: new HttpHeaders({}),
    };

    return this.http.post<any>(
      environment.server + '/users/forgot-password',
      {
        email: mail,
        username: username,
      },
      httpOptions,
    );
  }

  ModifyPassword(password: string, confirmpassword: string, token: string) {
    const httpOptions = {
      headers: new HttpHeaders({}),
    };

    return this.http.post<any>(
      environment.server + '/users/reset-password',
      {
        password: password,
        password_confirmation: confirmpassword,
        reset_token: token,
      },
      httpOptions,
    );
  }

  aboutCore() {
    let urlPath = environment.server.split('/');
    urlPath = urlPath[0] + '//' + urlPath[2];
    return this.http.get<any>(urlPath + '/info');
  }

  aboutTransfo() {
    return this.http.get<any>(environment.serverTransfo + '/info');
  }
}
