import { Component, OnInit } from '@angular/core';
import { AuthenticateService } from '../../services/authenticate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';
import { ngxLoadingAnimationTypes } from 'ngx-loading';

@Component({
  selector: 'app-password-change-page',
  templateUrl: './password-change-page.component.html',
  styleUrls: ['./password-change-page.component.scss'],
})
export class PasswordChangePageComponent implements OnInit {
  password: string;

  passwordConfirm: string;

  currentPassword: string;

  returnUrl: string;

  error = '';

  ngxLoadingConfig;

  loading = false;

  constructor(
    private authService: AuthenticateService,
    private route: ActivatedRoute,
    private notificationService: NotificationsService,
    private router: Router,
  ) {}

  ngOnInit() {
    // get return url from route parameters or default to '/admin/real-time'
    // this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/admin/dashboard';
    this.ngxLoadingConfig = {
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.5)',
      backdropBorderRadius: '7px',
      primaryColour: '#B10064',
      secondaryColour: '#F18E00',
      tertiaryColour: '#FFDD00',
    };
  }

  onSubmit() {
    if (this.password && this.passwordConfirm && this.password === this.passwordConfirm) {
      this.loading = true;
      this.authService
        .changePassword(this.currentPassword, this.password, this.passwordConfirm)
        .pipe(first())
        .subscribe(
          () => {
            this.loading = false;
            this.notificationService.success(
              'Mot de passe mis à jour avec succes',
              'Vous pouvez désormais vous connecter avec votre nouveau mot de passe.',
              {
                timeOut: 5000,
                showProgressBar: false,
                pauseOnHover: true,
                clickToClose: false,
                animate: 'fromRight',
              },
            );
            //this.router.navigate([this.returnUrl]);
            this.router.navigate(['/login']);
          },
          () => {
            this.notificationService.error(
              'Erreur',
              'Veuillez vérifier votre mot de passe et rééssayer',
              {
                timeOut: 5000,
                showProgressBar: false,
                pauseOnHover: true,
                clickToClose: false,
                animate: 'fromRight',
              },
            );
            this.loading = false;
          },
        );
    } else {
      this.notificationService.error('Erreur', 'Veuillez renseigner les champs obligatoires.', {
        timeOut: 5000,
        showProgressBar: false,
        pauseOnHover: true,
        clickToClose: false,
        animate: 'fromRight',
      });
    }
  }
}
