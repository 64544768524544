import * as RendementActions from './../actions/rendement.actions';

const initialState = {
  rendement_target: 'GLOBAL',
};

export function rendementReducer(state = initialState, action: RendementActions.Actions) {
  // Section 3NETWORK_NAVIGATION_CLOSE
  switch (action.type) {
    case RendementActions.SET_RENDEMENT_TARGET:
      return { ...state, rendement_target: action.payload };

    default:
      return state;
  }
}
