import { NetworkItem } from '../models/network-item.model';
import * as NetNavigationActions from './../actions/network-navigation.actions';
import { AllNetworkItems } from '../models/all-network-items.model';
import { AllMeterItems } from '../models/all-meter-items.model';
import { AllGeoItems } from '../models/all-geo-items.model';
import { AllNetworkItemsTransport } from '../models/all-network-items-transport.model';

const defaultItem: NetworkItem = {
  itemType: 'GLOBAL',
  itemId: null,
  item: null,
};

const defaultAllItems: AllNetworkItems = {
  postesSource: [],
  feeders: [],
  sousStations: [],
  feeders66: [],
  postesDistribution: [],
  transformateurs: [],
};
const defaultAllItemsTransport: AllNetworkItemsTransport = {
  postesSource: [],
  lignes: [],
  transformateurs: [],
};

const defaultAllMeterItems: AllMeterItems = {
  meters: [],
  groups: [],
};

const defaultAllGeoItems: AllGeoItems = {
  villes: [],
  communes: [],
  regions: [],
  delegations: [],
};

const initialState = {
  network_type: 'distribution',
  is_item_selected: false,
  all_network_items: defaultAllItems,
  all_network_items_transport: defaultAllItemsTransport,
  update_network_items: false,
  selected_item: defaultItem,
  update_meter_items: true,
  all_meter_items: defaultAllMeterItems,
  all_geo_items: defaultAllGeoItems,
};

export function networkNavReducer(state = initialState, action: NetNavigationActions.Actions) {
  // Section 3NETWORK_NAVIGATION_CLOSE
  switch (action.type) {
    case NetNavigationActions.NETWORK_NAVIGATION_NETWORK_TYPE:
      return {
        ...state,
        network_type: action.payload,
      };
    case NetNavigationActions.NETWORK_NAVIGATION_ITEM_SELECT:
      return {
        ...state,
        is_item_selected: true,
        selected_item: action.payload,
      };
    case NetNavigationActions.NETWORK_NAVIGATION_UPDATE_ITEMS:
      return {
        ...state,
        update_network_items: action.payload,
      };
    case NetNavigationActions.NETWORK_NAVIGATION_GET_ITEMS:
      return {
        ...state,
        all_network_items: action.payload,
      };
    case NetNavigationActions.NETWORK_NAVIGATION_GET_TRANSPORT_ITEMS:
      return {
        ...state,
        all_network_items_transport: action.payload,
      };
    case NetNavigationActions.NETWORK_NAVIGATION_UPDATE_METER_ITEMS:
      return {
        ...state,
        update_meter_items: action.payload,
      };
    case NetNavigationActions.NETWORK_NAVIGATION_GET_METER_ITEMS:
      return {
        ...state,
        all_meter_items: action.payload,
      };
    case NetNavigationActions.NETWORK_NAVIGATION_GET_GEO_ITEMS:
      return {
        ...state,
        all_geo_items: action.payload,
      };
    default:
      return state;
  }
}
