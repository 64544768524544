import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule, routingPages } from './app-routing-module';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { navigationReducer } from './admin/reducers/navigation.reducer';
import { rendementReducer } from './admin/reducers/rendement.reducer';
import { networkNavReducer } from './admin/reducers/network-navigation.reducer';
import { tempNavReducer } from './admin/reducers/temporal-navigation.reducer';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { BasicAuthInterceptor } from './interceptors/basic-auth.interceptor';
import { SuggestionService } from './admin/services/suggestion/suggestion.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PasswordChangePageComponent } from './pages/password-change-page/password-change-page.component';
import { NgxLoadingModule } from 'ngx-loading';
import { NotifService } from './admin/services/notifications/notif.service';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { AkiAuthModule } from '@akilee/auth';
import { environment } from '../environments/environment';
import { LoginForgotComponent } from './pages/login-forgot/login-forgot.component';

@NgModule({
  declarations: [
    AppComponent,
    routingPages,
    PasswordChangePageComponent,
    ResetPasswordPageComponent,
    LoginForgotComponent,
  ],
  imports: [
    BrowserModule,
    AkiAuthModule.forRoot({
      serverUrl: `${environment.uipServer}/api/v1`,
      loginFieldName: 'login',
      passwordFieldName: 'password',
      loginEndpoint: '/auth/login',
      checkTokenEndpoint: '/auth/verify-user',
      app: 'senami',
    }),
    HttpClientModule,
    StoreModule.forRoot({
      navigation: navigationReducer,
      rendement: rendementReducer,
      network_navigation: networkNavReducer,
      temp_navigation: tempNavReducer,
    }),
    AppRoutingModule,
    NgbModule,
    NgxPermissionsModule.forRoot(),
    FormsModule,
    BrowserAnimationsModule,
    SimpleNotificationsModule.forRoot({
      position: ['top', 'center'],
      maxStack: 10,
      icons: {
        success: '<span class="icon-check-sign icon-3x"></span>',
        alert: '<img src="../assets/images/close.png" alt="Fermer"/>',
        error: '<span  class="icon-bug icon-3x"></span>',
        info: '<span class="icon-info icon-3x"></>',
        warn: '<span class="icon-warning-sign icon-3x"></span>',
      },
    }),
    NgxLoadingModule.forRoot({}),
    ReactiveFormsModule,
  ],
  providers: [
    SuggestionService,
    NotifService,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
