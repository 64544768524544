import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Notification, NotificationsService, NotificationType } from 'angular2-notifications';
import { Subscription } from 'rxjs';

@Injectable()
export class NotifService {
  private idsToSubscription: Array<{ id: string; sub: Subscription }> = [];

  private defaultOptions = {
    clickToClose: true,
    showProgressBar: true,
    timeOut: 3000,
  };

  constructor(
    private sanitizer: DomSanitizer,
    private notificationsService: NotificationsService,
  ) {}

  getOptions() {
    return this.defaultOptions;
  }

  success(title: string, content: string, options?: any) {
    this.notificationsService.success(title, content, options);
  }

  error(title: string, content: string, options?: any) {
    this.notificationsService.error(title, content, options);
  }

  info(title: string, content: string, options?: any) {
    this.notificationsService.info(title, content, options);
  }

  subscribeToNotifClick(
    notification: Notification,
    actionClass: string,
    actionFct: Function,
  ): Subscription {
    return notification.click.subscribe((e: MouseEvent) => {
      if ((<HTMLElement>e.target).className.indexOf(actionClass) > -1) {
        this.notificationsService.remove(notification.id);
        this.notifDestroyed(notification.id);
        actionFct();
      }
    });
  }

  action(title: string, content: string, actionClass: string, actionFct: Function, icon?: string) {
    const html = `
            <div class="sn-title">${title}<div>
            <div class="sn-content">${content}<div>`;

    const options = {
      timeOut: 0,
      clickToClose: false,
      showProgressBar: false,
      clickIconToClose: true,
      // id: `custom${ new Date().getTime() }`
    };

    const notification = this.notificationsService.html(
      html,
      NotificationType.Error,
      options,
      icon,
    );

    const sub = this.subscribeToNotifClick(notification, actionClass, actionFct);

    this.idsToSubscription.push({ id: notification.id, sub });
  }

  action1(title: string, content: string, actionClass: string, actionFct: Function, icon?: string) {
    const html = `
            <div class="sn-title">${title}<div>
            <div class="sn-content">${content}<div>`;

    const options = {
      timeOut: 0,
      clickToClose: false,
      showProgressBar: false,
      clickIconToClose: true,
      // id: `custom${ new Date().getTime() }`
    };

    const notification = this.notificationsService.html(html, NotificationType.Warn, options, icon);

    const sub = this.subscribeToNotifClick(notification, actionClass, actionFct);

    this.idsToSubscription.push({ id: notification.id, sub });
  }

  notifDestroyed(id: string) {
    const subItem = this.idsToSubscription.find((i) => i.id === id);

    if (subItem && subItem.sub) {
      subItem.sub.unsubscribe();
      this.idsToSubscription = this.idsToSubscription.filter((i) => i.id !== id);
    }
  }
}
