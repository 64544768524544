import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AkiAuthService } from '@akilee/auth';
import {NotificationsService} from "angular2-notifications";

@Component({
  selector: 'app-login-forgot',
  templateUrl: './login-forgot.component.html',
  styleUrls: ['./login-forgot.component.scss'],
})
export class LoginForgotComponent implements OnInit {
  loginForm: FormGroup;

  loginFormFields = {
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    stayConnected: false,
  };

  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AkiAuthService,
    private notificationService: NotificationsService,
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group(this.loginFormFields);
  }

  get email() {
    return this.loginForm.get('email');
  }

  onSubmit() {
    this.isLoading = true;
    const { email } = this.loginForm.value;
    this.authService.forgetPassword(email).subscribe(
      (data) => {
        this.notificationService.success('Succès',
          ' Votre demande de ré-initialisation de mot de passe a été bien prise en compte.<br>\n' +
          'Vous allez recevoir un email avec les instructions à suivre pour accéder de nouveau à votre compte.', {
            timeOut: 5000,
            showProgressBar: false,
            pauseOnHover: true,
            clickToClose: false,
            animate: 'fromRight',
          });
        setTimeout(() => {
          this.router.navigate(['/admin/dashboard']);
        }, 5000);
      },
      (error: any) => {
        this.notificationService.error('Erreur', 'L\'adresse email saisie ne correspondant pas à celle fournie lors de la création de votre compte', {
          timeOut: 5000,
          showProgressBar: false,
          pauseOnHover: true,
          clickToClose: false,
          animate: 'fromRight',
        });
        this.isLoading = false;
      },
    );
  }
}
