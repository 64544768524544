import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { catchError, map } from 'rxjs/operators';
import { AkiAuthService } from '@akilee/auth';

/**
 * Guard qui vérifie que l'utilisateur est bien connecté
 * Sinon l'utilisateur est redirigé vers la page de connexion.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private authService: AkiAuthService,
  ) {}

  currentUser;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (window.localStorage) {
      this.currentUser = JSON.parse(localStorage.getItem('userToken'));
      if (this.currentUser && this.currentUser.user) {
        return this.authService.checkToken().pipe(
          map(() => {
            /**
             * Les permissions seront récupérées dans GDU
             */
            this.permissionsService.loadPermissions(
              Object.keys(this.currentUser?.user?.permissions),
            );
            const expireDate = new Date(
              (this.currentUser.createdAt + this.currentUser.expiresIn) * 1000,
            );
            const nowDate = new Date();
            if (expireDate > nowDate) {
              return true;
            } else {
              this.router.navigate(['/login'], {
                queryParams: { returnUrl: state.url },
              });
              return false;
            }
          }),
          catchError(() => {
            this.router.navigate(['/login'], {
              queryParams: { returnUrl: state.url },
            });
            return of(false);
          }),
        );
      }
    }
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
