<div class="password-reset-container">
  <div class="logo-container">
    <img src="../../assets/images/logoAkilee.svg" alt="" />
  </div>
  <br />
  <div class="password-reset-form-container div-highcharts-container">
    <div class="password-reset-form-title">Réinitialisation du mot de passe</div>
    <div class="form-container">
      <div class="form-group">
        <label class="control-label">Nouveau mot de passe</label>
        <input
          type="password"
          name="firstPassword"
          #firstPassword="ngModel"
          required
          [class.is-invalid]="firstPassword.invalid && firstPassword.touched"
          [(ngModel)]="password"
          class="form-control"
          placeholder="Veuillez entrer votre nouveau mot de passe"
          minlength="8"
        />
        <div *ngIf="firstPassword.touched && firstPassword.invalid" class="invalid-feedback">
          Le mot de passe est obligatoire.
        </div>
      </div>
      <div class="form-group">
        <label>Confirmation du mot de passe</label>
        <input
          type="password"
          name="secondPassword"
          #secondPassword="ngModel"
          required
          [class.is-invalid]="
            (secondPassword.invalid && secondPassword.touched) ||
            (password && password !== passwordConfirm)
          "
          [(ngModel)]="passwordConfirm"
          class="form-control"
          placeholder="Veuillez confirmer votre nouveau mot de passe"
        />
        <div *ngIf="secondPassword.invalid && secondPassword.touched" class="invalid-feedback">
          La confirmation du mot de passe est obligatoire.
        </div>
        <div *ngIf="password && password !== passwordConfirm" class="invalid-feedback">
          Les deux mots de passe saisis ne correspondent pas.
        </div>
      </div>
      <div class="form-group footer">
        <button
          type="submit"
          (click)="onSubmit()"
          class="btn btn-connect"
          [disabled]="
            firstPassword.invalid || secondPassword.invalid || password !== passwordConfirm
          "
        >
          Réinitialiser le mot de passe
        </button>
      </div>
    </div>
    <ngx-loading [show]="loading" [config]="ngxLoadingConfig"></ngx-loading>
  </div>
  <div *ngIf="firstPassword.errors?.minlength">
    <span style="color: red">Le mot de passe doit contenir au minimum 8 caractéres.</span>
  </div>
</div>
