import { Navigation } from '../models/navigation.model';
import * as NavigationActions from './../actions/navigation.actions';

// Section 1
const initialState: Navigation = {
  reduceSidebar: true,
  openNetworkNavigation: true,
  title: 'Hello',
};

// Section 2
export function navigationReducer(
  state: Navigation = initialState,
  action: NavigationActions.Actions,
) {
  // Section 3NETWORK_NAVIGATION_CLOSE
  switch (action.type) {
    case NavigationActions.NAVIGATION_EXPAND_SIDEBAR:
      return { ...state, reduceSidebar: false };

    case NavigationActions.NAVIGATION_REDUCE_SIDEBAR:
      return { ...state, reduceSidebar: true };

    case NavigationActions.NETWORK_NAVIGATION_CLOSE:
      return { ...state, openNetworkNavigation: false };

    case NavigationActions.NETWORK_NAVIGATION_OPEN:
      return { ...state, openNetworkNavigation: true };

    default:
      return state;
  }
}
