import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable()
export class SuggestionService {
  constructor(private _http: HttpClient) {}

  postSuggestion(
    nom: string,
    email: string,
    message: string,
    url: string = null,
    app_version: string = null,
    selected_date: string = null,
  ) {
    const today = new Date();

    const body = {
      nom: nom,
      email: email,
      message: message,
      url: url,
      app_version: app_version,
      selected_date: selected_date,
      date_envoi:
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDay() +
        ' ' +
        today.getHours() +
        ':' +
        today.getMinutes(),
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this._http.post(environment.server + '/suggestions', body, httpOptions);
  }
}
