// Section 1
import { Action } from '@ngrx/store';
import { NetworkItem } from '../models/network-item.model';
import { AllNetworkItems } from '../models/all-network-items.model';
import { AllMeterItems } from '../models/all-meter-items.model';
import { AllGeoItems } from '../models/all-geo-items.model';
import { AllNetworkItemsTransport } from '../models/all-network-items-transport.model';
import { NetworkItemTransport } from '../models/network-item-transport.model';

// Section 2
export const NETWORK_NAVIGATION_NETWORK_TYPE = '[NETWORK_NAVIGATION] NETWORK TYPE';
export const NETWORK_NAVIGATION_ITEM_SELECT = '[NETWORK_NAVIGATION] ITEM SELECT';
export const NETWORK_NAVIGATION_ITEM_TRANSPORT_SELECT =
  '[NETWORK_NAVIGATION] ITEM TRANSPORT SELECT';
export const NETWORK_NAVIGATION_UPDATE_ITEMS = '[NETWORK_NAVIGATION] UPDATE ITEMS';
export const NETWORK_NAVIGATION_UPDATE_ITEMS_TRANSPORT = '[NETWORK_NAVIGATION] UPDATE ITEMS';
export const NETWORK_NAVIGATION_GET_ITEMS = '[NETWORK_NAVIGATION] GET ITEMS';
export const NETWORK_NAVIGATION_GET_TRANSPORT_ITEMS = '[NETWORK_NAVIGATION] GET TRANSPORT ITEMS';
export const NETWORK_NAVIGATION_UPDATE_METER_ITEMS = '[NETWORK_NAVIGATION] UPDATE METER ITEMS';
export const NETWORK_NAVIGATION_GET_METER_ITEMS = '[NETWORK_NAVIGATION] GET METER ITEMS';
export const NETWORK_NAVIGATION_GET_GEO_ITEMS = '[NETWORK_NAVIGATION] GET GEO ITEMS';

export class SetNetworkType implements Action {
  readonly type = NETWORK_NAVIGATION_NETWORK_TYPE;

  constructor(public payload: string) {}
}

export class SelectNetworkItem implements Action {
  readonly type = NETWORK_NAVIGATION_ITEM_SELECT;

  constructor(public payload: NetworkItem) {}
}
export class SelectNetworkItemTransport implements Action {
  readonly type = NETWORK_NAVIGATION_ITEM_TRANSPORT_SELECT;

  constructor(public payload: NetworkItemTransport) {}
}

export class UpdateNetworkItems implements Action {
  readonly type = NETWORK_NAVIGATION_UPDATE_ITEMS;

  constructor(public payload: boolean) {}
}
export class UpdateNetworkItemsTransport implements Action {
  readonly type = NETWORK_NAVIGATION_UPDATE_ITEMS_TRANSPORT;

  constructor(public payload: boolean) {}
}

export class GetNetworkItems implements Action {
  readonly type = NETWORK_NAVIGATION_GET_ITEMS;

  constructor(public payload: AllNetworkItems) {}
}

export class GetNetworkItemsTransport implements Action {
  readonly type = NETWORK_NAVIGATION_GET_TRANSPORT_ITEMS;

  constructor(public payload: AllNetworkItemsTransport) {}
}

export class UpdateMeterItems implements Action {
  readonly type = NETWORK_NAVIGATION_UPDATE_METER_ITEMS;

  constructor(public payload: boolean) {}
}

export class GetMeterItems implements Action {
  readonly type = NETWORK_NAVIGATION_GET_METER_ITEMS;

  constructor(public payload: AllMeterItems) {}
}

export class GetGeoItems implements Action {
  readonly type = NETWORK_NAVIGATION_GET_GEO_ITEMS;

  constructor(public payload: AllGeoItems) {}
}

// Section 4
export type Actions =
  | SetNetworkType
  | SelectNetworkItem
  | UpdateNetworkItems
  | GetNetworkItems
  | UpdateMeterItems
  | GetMeterItems
  | GetGeoItems
  | GetNetworkItemsTransport
  | SelectNetworkItemTransport
  | UpdateNetworkItemsTransport;
