// Section 1
import { Action } from '@ngrx/store';

// Section 2
export const SET_RENDEMENT_TARGET = '[RENDEMENT] TARGET';

export class SetRendementTarget implements Action {
  readonly type = SET_RENDEMENT_TARGET;

  constructor(
    public payload:
      | 'GLOBAL'
      | 'POSTE_SOURCE'
      | 'FEEDER'
      | 'PRODUCTEUR'
      | 'POSTE'
      | 'TRANSFO'
      | 'LIGNE',
  ) {}
}

// Section 4
export type Actions = SetRendementTarget;
