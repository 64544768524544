// Section 1
import { Action } from '@ngrx/store';

// Section 2
export const NAVIGATION_REDUCE_SIDEBAR = '[NAVIGATION] REDUCE_SIDEBAR';
export const NAVIGATION_EXPAND_SIDEBAR = '[NAVIGATION] EXPAND_SIDEBAR';
export const NETWORK_NAVIGATION_OPEN = '[NETWORK_NAVIGATION] OPEN';
export const NETWORK_NAVIGATION_CLOSE = '[NETWORK_NAVIGATION] CLOSE';

// Section 3
export class ExpandSidebar implements Action {
  readonly type = NAVIGATION_EXPAND_SIDEBAR;
}

export class ReduceSidebar implements Action {
  readonly type = NAVIGATION_REDUCE_SIDEBAR;
}

export class OpenNetworkNav implements Action {
  readonly type = NETWORK_NAVIGATION_OPEN;
}

export class CloseNetworkNav implements Action {
  readonly type = NETWORK_NAVIGATION_CLOSE;
}

// Section 4
export type Actions = ExpandSidebar | ReduceSidebar | CloseNetworkNav | OpenNetworkNav;
