import { Component, OnInit } from '@angular/core';
import { AuthenticateService } from '../../services/authenticate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { SuggestionService } from '../../admin/services/suggestion/suggestion.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ngxLoadingConfig } from 'src/util/variables';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AkiAuthService } from '@akilee/auth';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  username;

  nom;

  message;

  userEmail;

  returnUrl: string;

  error = '';

  mailUtilisateur;

  nomUtilisateur;

  ngxLoadingConfig;

  loading = false;

  aboutCore;

  aboutTransfo;

  aboutApp;

  loginForm!: FormGroup;

  permissions = [];

  loginFormFields = {
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    stayConnected: false,
  };

  get email() {
    return this.loginForm?.get('email');
  }

  get password() {
    return this.loginForm?.get('password');
  }

  constructor(
    private authService: AuthenticateService,
    private akiAuthService: AkiAuthService,
    private route: ActivatedRoute,
    private _modalService: NgbModal,
    private router: Router,
    private notificationService: NotificationsService,
    private _suggestionService: SuggestionService,
    private fb: FormBuilder,
  ) {
    this.ngxLoadingConfig = ngxLoadingConfig;
  }

  ngOnInit() {
    // reset login status
    this.loginForm = this.fb.group(this.loginFormFields);
    this.akiAuthService.logout();
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '';
  }

  onSubmit() {
    this.loading = true;
    const { email, password } = this.loginForm?.value;
    this.akiAuthService.login(email, password).subscribe(
      (userInfo) => {
        this.permissions = Object.keys(userInfo?.user?.permissions);
        this.loading = false;
        if (this.permissions.includes('APP_DISTRIBUTION')) {
          this.router.navigate([this.returnUrl]);
        } else {
          this.notificationService.error('Erreur', 'Identifiants incorrects', {
            timeOut: 5000,
            showProgressBar: false,
            pauseOnHover: true,
            clickToClose: false,
            animate: 'fromRight',
          });
        }
      },
      () => {
        this.loading = false;
        this.notificationService.error('Erreur', 'Identifiants incorrects', {
          timeOut: 5000,
          showProgressBar: false,
          pauseOnHover: true,
          clickToClose: false,
          animate: 'fromRight',
        });
      },
    );
  }

  sendSuggestion() {
    if (!this.userEmail) {
      this.notificationService.info('Email obligatoire', 'Veuillez renseigner votre adresse mail', {
        timeOut: 5000,
        showProgressBar: false,
        pauseOnHover: true,
        clickToClose: false,
        animate: 'fromRight',
      });
    } else if (!this.message) {
      this.notificationService.info('Message obligatoire', 'Veuillez renseigner le message', {
        timeOut: 5000,
        showProgressBar: false,
        pauseOnHover: true,
        clickToClose: false,
        animate: 'fromRight',
      });
    } else {
      this.loading = true;
      this._suggestionService
        .postSuggestion(
          this.nom,
          this.userEmail,
          this.message,
          window.location.origin,
          environment.version,
        )
        .subscribe(
          () => {
            this.notificationService.success(
              'Message envoyé',
              'Votre message a ete envoye avec succes',
              {
                timeOut: 5000,
                showProgressBar: false,
                pauseOnHover: true,
                clickToClose: false,
                animate: 'fromRight',
              },
            );
            this.loading = false;
          },
          () => {
            this.notificationService.error('Erreur', 'Veuillez réessayer plus tard', {
              timeOut: 5000,
              showProgressBar: false,
              pauseOnHover: true,
              clickToClose: false,
              animate: 'fromRight',
            });
            this.loading = false;
          },
        );
      this.nom = '';
      this.message = '';
    }
  }

  openModal(content) {
    this._modalService.open(content, { centered: true, size: 'lg' });
  }

  envoyerLiendeReinitialisation() {
    if (this.nomUtilisateur && this.mailUtilisateur) {
      // const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.authService.forgetPassword(this.mailUtilisateur, this.nomUtilisateur).subscribe(
        () => {
          this.notificationService.success(
            'Demande bien reçu!',
            'Vous recevrez bientôt par mail un lien pour réinitialiser votre mot de passe.',
            {
              timeOut: 5000,
              showProgressBar: false,
              pauseOnHover: true,
              clickToClose: false,
              animate: 'fromRight',
            },
          );
        },
        () => {
          this.notificationService.error('Erreur', 'username et/ou email invalide! ', {
            timeOut: 5000,
            showProgressBar: false,
            pauseOnHover: true,
            clickToClose: false,
            animate: 'fromRight',
          });
        },
      );

      this.mailUtilisateur = '';
      this.nomUtilisateur = '';
    } else {
      this.notificationService.error(
        'Erreur',
        'Veuillez vérifier que les informations saisies sont correctes.',
        {
          timeOut: 5000,
          showProgressBar: false,
          pauseOnHover: true,
          clickToClose: false,
          animate: 'fromRight',
        },
      );
    }
  }

  aboutApplications() {
    this.aboutApp = environment.version;
    this.authService.aboutCore().subscribe((data) => {
      this.aboutCore = data;
    });
    this.authService.aboutTransfo().subscribe((data) => {
      this.aboutTransfo = data;
    });
  }
}
