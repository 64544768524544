import { IDate } from '../models/date.model';
import * as TempNavigationActions from './../actions/temporal-navigation.actions';
import * as dayjs from 'dayjs';

const today: dayjs.Dayjs = dayjs();

const defaultItem: IDate = {
  type: 'MOIS',
  dateStartStr: today.startOf('month').format('YYYY-MM-DD HH:mm:ss'),
  dateEndStr: today.endOf('month').format('YYYY-MM-DD HH:mm:ss'),
};

const initialState = {
  selected_date: defaultItem,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export function tempNavReducer(state = initialState, action: TempNavigationActions.Actions) {
  switch (action.type) {
    case TempNavigationActions.SET_TEMPORAL_NAVIGATION_RANGE:
      return {
        ...state,
        selected_date: action.payload,
      };
    default:
      return state;
  }
}
